/* eslint-disable array-callback-return */
import { color } from '@stockbit/ui';

const MONTH_MAPPING = {
  1: 'jan',
  2: 'feb',
  3: 'mar',
  4: 'apr',
  5: 'may',
  6: 'jun',
  7: 'jul',
  8: 'aug',
  9: 'sep',
  10: 'oct',
  11: 'nov',
  12: 'dec',
};

const GENERAL_STATEMENT_TYPE = [
  { name: 'Quarterly', value: 1 },
  { name: 'Annual', value: 2 },
  { name: 'TTM', value: 3 },
  { name: 'Interim YTD', value: 4 },
  { name: 'Q1', value: 5 },
  { name: 'Q2', value: 6 },
  { name: 'Q3', value: 7 },
  { name: 'Q4', value: 8 },
];

const NON_ID_STATEMENT_TYPE = [...GENERAL_STATEMENT_TYPE];

const GROWTH_STATEMENT_TYPE = [
  { name: 'QoQ Growth', value: 9 },
  { name: 'Quarter YoY Growth', value: 10 },
  { name: 'YTD YoY Growth', value: 11 },
  { name: 'Annual YoY Growth', value: 12 },
  { name: '3 Year CAGR', value: 13 },
];

const ID_STATEMENT_TYPE = [...GENERAL_STATEMENT_TYPE, ...GROWTH_STATEMENT_TYPE];

const EMITTEN_TYPE = {
  REKSA_DANA: 'reksadana',
};

function colorHeatmap(val) {
  if (val > 0 && val <= 3) return color['seasonality-darkest-green'];
  if (val > 3 && val <= 8) return color['seasonality-medium-green'];
  if (val > 8 && val <= 15) return color['seasonality-green'];
  if (val > 15) return color['seasonality-bright-green'];
  if (val === 0) return color.dimGray;
  if (val < 0 && val >= -3) return color['seasonality-darkest-red'];
  if (val < -3 && val >= -8) return color['seasonality-medium-red'];
  if (val < -8 && val >= -15) return color['seasonality-red'];

  return color['seasonality-bright-red'];
}

function percentageColorHeatmap2(val) {
  if (val === 0) return color.dimGray;
  if (val < 50) return color['seasonality-medium-red'];

  return color['seasonality-medium-green'];
}

const formatingData = (data) => {
  const formatedData = {};

  Object.keys(data).forEach((keyParent) => {
    formatedData[keyParent] = {};

    Object.keys(data[keyParent]).forEach((keyChild) => {
      const numberAfterComma = data[keyParent][keyChild].toString().split('.');
      const mockupValue = data[keyParent][keyChild];

      formatedData[keyParent][keyChild] =
        numberAfterComma.length < 2 ? mockupValue : mockupValue.toFixed(2);
    });
  });

  return formatedData;
};

const formatDataYear = (priceChangeData) => {
  const formattedData = {};

  Object.keys(priceChangeData).map((index) => {
    const priceData = priceChangeData[index];
    const monthData = {};

    priceData.months.map((data) => {
      const numberAfterComma = data.price_change.toString().split('.');
      const mockupValue = data.price_change;

      const monthName = MONTH_MAPPING[data.month];
      monthData[monthName] =
        numberAfterComma.length < 2 ? mockupValue : mockupValue.toFixed(2);
    });

    formattedData[priceData.year] = monthData;
  });

  return formattedData;
};

/**
 * Get financial data statement type based on user country setting
 * @param country - User country
 * @returns {Array}
 */
const getStatementType = (country = 'ID') => {
  if (country !== 'ID') {
    return NON_ID_STATEMENT_TYPE;
  }

  return ID_STATEMENT_TYPE;
};

/**
 * Clean symbol prefix
 * @param {string} symbol
 * @returns {string}
 */
const cleanSymbol = (symbol: string) => {
  const regex = /klse-/gi;
  return symbol.replace(regex, '');
};

/**
 * Default API microservice response object
 */
const ENTITY = {
  seasonality: {
    price_change: [],
    avg: {},
    up: {},
    down: {},
    totalmonths: {},
    prob: {},
  },
};

/**
 * Translate new seasonality response API microservice into the old response format
 * @param {Object} SeasonalityResponse - The Response body.data from API seasonality microservice
 * @param {Array} SeasonalityResponse.price_change - The price change data
 * @param {Object} SeasonalityResponse.avg - The average seasonality data
 * @param {Object} SeasonalityResponse.up - The seasonality data up
 * @param {Object} SeasonalityResponse.down - The seasonality data down
 * @param {Object} SeasonalityResponse.totalmonths - The seasonality total months
 * @param {Object} SeasonalityResponse.prob - The seasonality probability
 */
const translateResponseSeasonality = ({
  price_change = ENTITY.seasonality.price_change,
  avg = ENTITY.seasonality.avg,
  up = ENTITY.seasonality.up,
  down = ENTITY.seasonality.down,
  totalmonths = ENTITY.seasonality.totalmonths,
  prob = ENTITY.seasonality.prob,
}) => {
  const convertMonthToNumber = (dataToConvert = {}) => {
    const monthToNumber = {
      jan: '1',
      feb: '2',
      mar: '3',
      apr: '4',
      may: '5',
      jun: '6',
      jul: '7',
      aug: '8',
      sep: '9',
      oct: '10',
      nov: '11',
      dec: '12',
      year: '13',
    };
    return Object.keys(dataToConvert).reduce((acc, curr) => ({ ...acc, [monthToNumber[curr]]: dataToConvert[curr] }), {});
  };

  return price_change.reduce(
    (acc, curr) => {
      const dataYear = curr.months.reduce((_acc, _curr) => ({
        ..._acc,
        [_curr.month]: `${_curr.price_change}|${_curr.color}`,
      }), {});
      return { ...acc, [curr.year]: { ...dataYear } };
    },
    {
      avg: convertMonthToNumber(avg),
      up: convertMonthToNumber(up),
      down: convertMonthToNumber(down),
      totalmonths: convertMonthToNumber(totalmonths),
      prob: convertMonthToNumber(prob),
    },
  );
};

const transformResponseSeasonalityFromServer = (responseData) => {
  const data = {
    avg: responseData.avg,
    down: responseData.down,
    price_change: responseData.price_change,
    prob: responseData.prob,
    totalmonths: responseData.total_months,
    up: responseData.up,
  };
  const newData = {};
  for (const key in data) {
    newData[key] = {};
    if (data[key]?.columns) {
      data[key].columns.forEach((col) => {
        let value = `${col.value}|${col.color}`;
        if (['up', 'down', 'totalmonths'].includes(key)) {
          value = col.value;
        }
        newData[key][col.name.toLowerCase()] = value;
      });
    } else {
      const monthNumber = {
        Jan: '1',
        Feb: '2',
        Mar: '3',
        Apr: '4',
        May: '5',
        Jun: '6',
        Jul: '7',
        Aug: '8',
        Sep: '9',
        Oct: '10',
        Nov: '11',
        Dec: '12',
        Year: '13',
      };
      newData[key] = data[key].map((dt) => ({
        year: dt.row,
        months: dt.columns.map((col) => ({ month: monthNumber[col.name], price_change: col.value, color: col.color })),
      }));
    }
  }
  return newData;
};

const isReksaDana = (typeCompany: string) => {
  if (!typeCompany) return false;
  return typeCompany.toLowerCase() === EMITTEN_TYPE.REKSA_DANA;
};

export default {
  colorHeatmap,
  percentageColorHeatmap2,
  formatingData,
  formatDataYear,
  getStatementType,
  GROWTH_STATEMENT_TYPE,
  cleanSymbol,
  translateResponseSeasonality,
  transformResponseSeasonalityFromServer,
  MONTH_MAPPING,
  isReksaDana,
};
